<template>
   <div class="auth-page">
        <div class='auth-header'>
            <div class='header-img'> </div>
        </div> 
       
            <div class='auth-contain'>
                <div class='main-text'>请上传您的身份证正反面照片</div>
                <div class='i-body'>
                    <!-- 本地调试 -->
                    <!-- <div class='i-photo'>
                        <input ref={this.fileFront} accept='image/*' type='file' @change='uploadeImg'/>
                        <img class='i-photo-img'  alt='身份证正面照片'
                            :src="imgFrontUrl ? 'data:image/jpg;base64,'+ imgFrontUrl : identityFront"/>
                    </div> -->

                    
                <div class='i-photo' @click="uploadeImg">
                        <img 
                        class='i-photo-img' 
                        :src="imgFrontUrl ? 'data:image/jpg;base64,'+ imgFrontUrl : identityFront"/>
                </div>
                <div class='i-photo' @click='uploadBackImg'>
                    <img 
                        class='i-photo-img' 
                        :src="imgBackUrl ? 'data:image/jpg;base64,'+ imgBackUrl : identityBack"/>
                </div>
                </div>
                <div class='i-req'>
                    <div class='req-header'>
                        <i class="req-frist"></i>
                        <span>拍摄照片要求</span>
                        <i class="req-secord"></i>
                    </div>
                    <div class='req-mian'>
                        <div v-for='item in intro' :key="item.type" class="req-main-img">
                        <img :src='item.img' />
                        </div>
                    </div>
                </div>

                <div class="auth-footer" @click='authVerify'>
                 认证
                </div>
            </div>
           
      
        <Modal :show="showback" @cancel="cancel" :showCancel="true" class='infrom-modal' :title="'信息确认'">
            <div class='bank-line'>
                <span class='bank-line-name'>姓名</span>
                    <!-- :value='userInfo.nickName'value="赵达" -->
                <input class='bank-input'  :value="realName" @change='authChange($event)'/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>身份证号码</span>
                    <!-- :value='userInfo.cardId'value="赵达" -->
                <input class='bank-input'  :value="idCardNumber" @change='IDChange($event)'/>
            </div>
            <div class='bank-line'>
                <span class='bank-line-name'>身份证有效期</span>
                    <!-- :value='userInfo.timeDate'value="赵达" -->
                <input class='bank-input'  :value="validDate" @change='dateChange($event)'/>
            </div>
            <div class='bank-picture'>
                <div class='pic-img'>
                    <img :src="'data:image/jpg;base64,'+imgFrontUrl" />
                    <i className='m-shui'></i>
                </div>
                <div class='pic-img pic-c'>
                    <img :src="'data:image/jpg;base64,'+imgBackUrl" />
                    <i className='m-shui'></i>
                </div>
            </div>
            <div class='bank-btn' @click='confirmOcr'>
                确认
            </div>
        </Modal>
   </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'
import Modal from '@/components/Modal/Modal.vue'
import {routerTag } from '../../utils/index'
import { Toast } from "vant"


export default ({
    components: {
        Modal
    },
    
    setup() {
        const state=reactive({
            showback: false,
            identityFront:'http://supertest.jqtianxia.cn/static/cardface.png',//身份证正deletable: false,
            identityBack:'http://supertest.jqtianxia.cn/static/cardreserve.png',//身份证反
            imgFrontUrl: null, //正
            imgBackUrl: null,//反
            intro: [
                {
                type: 'http://supertest.jqtianxia.cn/static/imgO.png',
                img: 'http://supertest.jqtianxia.cn/static/imgO.png',
                txt: "标准拍摄"
                },
                {
                type: 'http://supertest.jqtianxia.cn/static/imgS.png',
                img: 'http://supertest.jqtianxia.cn/static/imgS.png',
                txt: "边框缺失"
                },
                {
                type: 'http://supertest.jqtianxia.cn/static/imgT.png',
                img: 'http://supertest.jqtianxia.cn/static/imgT.png',
                txt: "照片模糊"
                },
                {
                type: 'http://supertest.jqtianxia.cn/static/imgF.png',
                img: 'http://supertest.jqtianxia.cn/static/imgF.png',
                txt: "闪光强烈"
                },
            ], 
            fileName:'',
            fileKey:'',
            ocrToken:'',
            ocrChannel:'',
            ocrKey:'20a738fd91504477a33ea4c60ba4b901',
            ocrSecret:'c56a20723a194925aa5eaea3ef09a061',
            frontImgPath:null,
            reverseImgPath:null,
            typeU:null,
            userInfo:null,
            realName:'',//姓名
            idCardNumber:'',//身份证
            validDate:'',//有效期
            ocrInfo:null,//正面数据新诺威尔
            ocrBackInfo:null,//反面数据新诺威尔
        })
      
       
        const modalCheck=(e)=>{
            state.showback = false
        }
        //实名认证获取token
        const authToken=async()=>{
            const res=await API.authToken({ })
            if(res){
                state.ocrToken=res.ocrToken
                state.ocrChannel=res.ocrChannel
            }
            console.log(res,86);
        }
        const getNewFormDate=()=>{
            var date=new Date();
            var year=date.getFullYear();
            var month=date.getMonth()+1;
            var srtDate=date.getDate();
            if(month>=1 && month<=9){
            month='0'+month
            }
            if(srtDate>=1 && srtDate<=9){
            srtDate='0'+srtDate
            }
            var currentdate=year+month+srtDate
            return currentdate
        
        }
           //face++  ocrChannel=1 需要调取接口
        const uploadeImg=()=>{
            console.log(12);
            window.TDAPP.onEvent("实名认证身份证正面点击-进入");
            var timestamp = (new Date()).getTime();
            state.typeU=1
            state.fileKey= 'CERT/'+ `${getNewFormDate()}/` + timestamp +'.jpg';
            
            // applyTokenDo(uploadFile); //本地调试使用
            if(state.ocrChannel==1){
                APP.Camera_UP(state.ocrToken, state.ocrChannel,state.fileKey,function(res){ 
                    if(res){
                        state.imgFrontUrl=res
                        // console.log(res,99);
                        applyTokenDo(uploadFile);
                       
                    }
                })
            }else{
                console.log(777)
                APP.XINNUO_Camera_UP(state.ocrToken, state.ocrChannel,state.fileKey,state.ocrKey,state.ocrSecret,function(res){
                    // APP.Alert(res)
                   console.log(res.idcard,99)
                   //在原生端如果没有添加新诺韦尔调取摄像头上传的标识（正面或反面）的情况下，做些一些逻辑判断

                    if(res){
                        const result=JSON.parse(res.idcard)
                        const {strSex,strAddress,strName,strID,}=result
                        if(strName){
                               state.imgFrontUrl=res.imgUrl
                                state.ocrInfo=result
                                state.realName=strName
                                state.idCardNumber=strID
                                // window.localStorage.setItem('authName',strName)
                                // console.log(state.realName,state.idCardNumber,strSex,strAddress,885);
                                applyTokenDo(uploadFile);
                        }else{
                            //提示
                            Toast('请上传身份证正面照')
                            return false;
                        }
                     
                    }
                })
            }
        }
        const uploadBackImg=()=>{
            window.TDAPP.onEvent("实名认证身份证反面点击-进入");
            state.typeU=2
            var timestamp = (new Date()).getTime();
            state.fileKey= 'CERT/'+ `${getNewFormDate()}/` + timestamp +'.jpg';
            if(state.ocrChannel==1){
                APP.Camera_UP(state.ocrToken, state.ocrChannel,state.fileKey,function(res){
                    //  APP.Alert(res)
                    
                    if(res){
                       
                        state.imgBackUrl=res
                        applyTokenDo(uploadFile);
                    }
                }) 
            }else{
                //新诺威尔
                APP.XINNUO_Camera_UP(state.ocrToken, state.ocrChannel,state.fileKey,state.ocrKey,state.ocrSecret,function(res){
                    if(res){
                        const result=JSON.parse(res.idcard)
                        const {strValidity}=result
                        if(strValidity){
                            state.imgBackUrl=res.imgUrl
                            state.ocrBackInfo=result
                            const p=state.ocrBackInfo.strValidity
                            const k= p.split('-')[0].substr(0,4)
                            const y=p.split('-')[0].substr(4,2)
                            const z=p.split('-')[0].substr(6,2)

                            const r= p.split('-')[1].substr(0,4)
                            const e=p.split('-')[1].substr(4,2)
                            const h=p.split('-')[1].substr(6,2)
                            const l=`${k}.${y}.${z}`
                            const f=`${r}.${e}.${h}`
                            const vdate=`${l}-${f}`
                            state.validDate=vdate
                            console.log(state.validDate);
                            applyTokenDo(uploadFile);
                        }else{
                            Toast('请上传身份证反面照')
                            return false;
                        }
                       
                    }
                })
            }
        }
        const  base64ToFile=(urlData) =>{
            let arr = urlData.split(",");
            let mime = arr[0].match(/:(.*?);/)[1];
            let bytes = atob(arr[1]); // 解码base64
            let n = bytes.length;
            let ia = new Uint8Array(n);
            while (n--) {
                ia[n] = bytes.charCodeAt(n);
            }
            return new File([ia], `${new Date().getTime()}.jpg`, { type: mime });
        }
       
        //在没有后端接口的情况下使用
        // var client = new OSS({
        //     region:'oss-cn-hangzhou',
        //     accessKeyId:'LTAI5tCst9tt6pZ7NP5gKrCJ',
        //     accessKeySecret:'lytia7qITEvakQ7bIXSze5kur0tA6p',
        //     bucket:'j-resource-prod',
        // });
        // const applyTokenDo = function (func) {
        //     return func(client);

        // };
        //有接口的情况下
      
        
        const applyTokenDo =async function (func) {
            //请求后台接口
            await API.OssToken({ }).then(function(result){
                console.log(result,887)
                    var client = new OSS({
                        region:'oss-cn-hangzhou',
                        accessKeyId: result.accessKey,
                        accessKeySecret: result.accessSecret,
                        stsToken: result.securityToken,
                        bucket:'j-resource-prod',
                    });
                    return func(client);
                })
        };


        const uploadFile = function (client) {
            Toast.loading({
                        message: '上传中...',
                        forbidClick: true,
            });
            var key=state.fileKey;
            if(state.typeU=='1'){
                console.log(777)
                var file=base64ToFile('data:image/jpg;base64,' + state.imgFrontUrl)
            }else{
                var file=base64ToFile('data:image/jpg;base64,' + state.imgBackUrl)
            }
            
            return client.multipartUpload(key,file).then(function (res) {
                    console.log(key,file)
                    if(state.typeU=='1'){
                        state.frontImgPath=res.res.requestUrls[0].split('?')[0]
                        window.localStorage.setItem('imgPathO',state.frontImgPath)       
                    }else{
                        state.reverseImgPath=res.res.requestUrls[0].split('?')[0]
                        window.localStorage.setItem('imgPathT',state.reverseImgPath)
                    } 
               
            }).catch((err)=>{
                // APP.Alert(JSON.stringify(err))
                console.log(err,888)
            })
        }
        //新诺韦尔 ocrChannel=2 app返回身份信息，不调接口 

        const authVerify=()=>{
            window.TDAPP.onEvent("实名认证点击-认证-进入");
            if(state.imgFrontUrl==null){
                Toast('请上传身份证正面照')
                return false;
            }
            if(state.imgBackUrl==null){
                Toast('请上传身份证反面照')
                return false;
            }
            if(state.ocrChannel==1){
                 
                //调ocr 
                autOcr()
            }else{
                state.showback = true
            }
           
        }
        const cancel = (show) => {
            state.showback = show
            
        }
         //实名认证ocr
        const autOcr= async ()=>{
           const p= window.localStorage.getItem('imgPathO')
           const k= window.localStorage.getItem('imgPathT')
            const faceRes= await API.authOcr({ 
                frontImgPath:p, //身份证正面
                reverseImgPath:k //身份证反面
            })
            console.log(faceRes,'faceocr')
            if(faceRes.cardInfo.realName && faceRes.cardInfo.validDate){
                // const {}=faceRes
                    // console.log(,faceres.realName,faceres.idCardNumber,665)
                    state.userInfo=faceRes.cardInfo
                    state.realName=faceRes.cardInfo.realName,//姓名
                    state.idCardNumber=faceRes.cardInfo.idCardNumber,//身份证
                    state.validDate=faceRes.cardInfo.validDate,//有效期
                    // window.localStorage.setItem('authName',result.cardInfo.realName)
                    state.showback = true
            }else{
               //这里再做一些判断，上传的正反面错误的情况下的提示
                Toast('请上传正确的身份证正反面照')
                return false;
            }
            
           

           
        }
        const authChange=(e)=>{
            state.realName=e.target.value
            // console.log(e.target.value,12);

        }
        const IDChange=(e)=>{
            state.idCardNumber=e.target.value
            // console.log(state.userInfo.idCardNumber,997);
        }
        const dateChange=(e)=>{
            state.validDate=e.target.value
        }
       
        const confirmOcr=()=>{
            window.TDAPP.onEvent("实名认证提交-进入");
            if(state.ocrChannel==1){
                authSub()
            }else{
                SubOcr()
            }
           
        }
         //实名认证提交 face++
        const authSub= async ()=>{
            const authOpath= window.localStorage.getItem('imgPathO')
            const authTpath= window.localStorage.getItem('imgPathT')
            
            if(state.realName==''){
                Toast('请填写姓名')
                return false;
            }
             if(state.idCardNumber==''){
                Toast('请填写身份证号码')
                return false;
            }
             if(state.validDate==''){
                Toast('请填写身份证有效期')
                return false;
            }
            window.localStorage.setItem('authName',state.realName)
            const result= await API.authSubmit({ 
                birthday:state.userInfo.birthday ,
                certType: "1",
                gender: state.userInfo.gender, //1.男 2.女 3.未提供
                validDate: state.validDate ,//"2016.01.04-2036.01.04"
                reverseImgPath: authTpath,
                frontImgPath:  authOpath,
                address: state.userInfo.address,
                race: state.userInfo.race,
                issueBy: state.userInfo.issueBy,
                realName: state.realName,
                idCardNumber: state.idCardNumber
            })
            console.log(result,777);
            if(result.authResult.authState==true){
                    const faceUrl=routerTag+"authFace"
                    APP.JUMP_H5(faceUrl,'人脸识别') 
            }
          
        }
        const SubOcr=async()=>{
           
            const authOpath= window.localStorage.getItem('imgPathO')
            const authTpath= window.localStorage.getItem('imgPathT')
            let  gender;
            if(state.ocrInfo.strSex='女'){
                gender=2
            }else{
                gender=1
            }
            const xinbirthday=`${state.ocrInfo.strYear}-${state.ocrInfo.strMonth}-${state.ocrInfo.strDay}`
             if(state.realName==''){
                Toast('请填写姓名')
                return false;
            }
             if(state.idCardNumber==''){
                Toast('请填写身份证号码')
                return false;
            }
             if(state.validDate==''){
                Toast('请填写身份证有效期')
                return false;
            }
            window.localStorage.setItem('authName',state.realName)
            const result= await API.authSubmit({ 
                birthday:xinbirthday ,
                certType: "1",
                gender:gender, //1.男 2.女 3.未提供
                validDate:state.validDate ,//"2016.01.04-2036.01.04"
                reverseImgPath: authTpath,
                frontImgPath: authOpath,
                address: state.ocrInfo.strAddress,
                race: state.ocrInfo.strNation,
                issueBy: state.ocrBackInfo.strAuthority,
                realName: state.realName,
                idCardNumber: state.idCardNumber
            })
            console.log(result,777);
            if(result){
                const targetUrl=routerTag+"authFace"
                APP.JUMP_H5(targetUrl,'人脸识别') 
              
            }
        }
   
        onMounted(()=>{
        //   APP.IS_SHOW_BACK(false)
            APP.SET_TITLE('实名认证')
            authToken()
        })
        return{
            ...toRefs(state),
            authVerify,
            cancel,
            modalCheck,
            authSub,
            uploadeImg,
            uploadBackImg,
            autOcr,
            confirmOcr,
            authChange,
            IDChange,
            dateChange,
            
        }
    },
})
</script>